import * as Icons from "@mui/icons-material";
import { Chip } from "@mui/material";
import React from "react";
import { UserPermissions } from "../../api/apiModelTypes";
import { IS_DEV_ENVIRONMENT, IS_PRODUCTION_ENVIRONMENT } from "../../constants";

/**
 * A single scene.
 */
export interface Scene {
	/**
	 * The label for the scene.
	 * Will be rendered as child of a Material.Typography component
	 */
	label: React.ReactNode;
	/**
	 * The icon to render.
	 */
	icon?: Icons.SvgIconComponent;
	/**
	 * If present, will be checked before rendering scene option
	 */
	permissions?: UserPermissions[];
	/**
	 * Used by nav to determine scene visibility by user permissions
	 */
	display: boolean;
}

/**
 * The configuration for a parent scene with children scenes.
 */
export interface MultiScene extends Scene {
	/**
	 * Array of children scenes.
	 */
	scenes: SingleScene[];
}

export interface SingleScene extends Scene {
	/**
	 * The path to redirect upon selection.
	 */
	path: string;
	/**
	 * The boolean to indicate the scene is a link to an external source.
	 */
	isExternal?: boolean;
}

export enum SceneLabels {
	VEHICLES = "Vehicles",
	POLICIES = "Policies",
	POLICY_ELEMENTS = "Policy Elements",
	STREAMS = "Streams",
	ADMINISTRATION = "Administration",
	DOCUMENTATION = "Documentation",
	POLICY_GOVERNANCE = "Policy Governance"
}

/**
 * Menu options is an array of MultiScene or SingleScene
 * This is because the nav behaves in a way that either has an array of sub scenes or a path to directly navigate to
 */
export const MENU_OPTIONS: (MultiScene | SingleScene)[] = [
	{
		label: SceneLabels.VEHICLES,
		icon: Icons.DirectionsCar,
		path: "/vins",
		permissions: [
			// Roles required for scene access
			// USER_ROLES.ADMIN,
			// USER_ROLES.VIEWER,
			// USER_ROLES.VIN_MANAGER
			UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
			UserPermissions.ADA_API_VIN_PROFILE_READ_EU
		],
		display: true,
		scenes: [
			{
				label: "Vehicles",
				path: "/vins",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR,
					// USER_ROLES.VIEWER
					UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
					UserPermissions.ADA_API_VIN_PROFILE_READ_EU
				],
				display: true
			},
			{
				label: "VCR Recording",
				path: "/vcr",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.VIEWER,
					// USER_ROLES.VIN_MANAGER
					UserPermissions.ADA_API_VIN_PROFILE_READ_NA,
					UserPermissions.ADA_API_VIN_PROFILE_READ_EU
				],
				display: true
			}
		]
	},
	{
		label: SceneLabels.POLICIES,
		icon: Icons.Code,
		path: "policies",
		scenes: [
			{
				label: "Policies",
				path: "/policies",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR,
					// USER_ROLES.VIEWER
					UserPermissions.ADA_API_POLICY_READ_NA,
					UserPermissions.ADA_API_POLICY_READ_EU,
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: "Policy Creation",
				path: "policies/create/",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: (
					<>
						<span
							style={{
								marginRight: "1rem",
								display: "inline-flex",
								alignItems: "center"
							}}
						>
							Policy Builder
							<Chip
								label="Experimental"
								icon={
									<Icons.Science
										style={{
											color: "#606c76" // greyish font color
										}}
									/>
								}
								size="small"
								variant="outlined"
								style={{ marginLeft: "0.5rem" }}
								sx={{
									backgroundColor: "#e0f7fa", // Light teal background
									borderColor: "#00bcd4" // Bright blue border
								}}
							/>
						</span>
					</>
				),
				path: "policies/create/v2",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: "Testing",
				path: "vehicle-testing",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.FEATURE_NAMER,
					// USER_ROLES.VIN_MANAGER
					UserPermissions.ADA_API_FEATURE_WRITE_NA,
					UserPermissions.ADA_API_FEATURE_WRITE_EU,
					UserPermissions.ADA_API_POLICY_ASSIGNMENT_NA,
					UserPermissions.ADA_API_POLICY_ASSIGNMENT_EU
				],
				display: true
			},
			{
				label: "Feature Names",
				path: "feature-names",
				permissions: [
					// Roles required for scene access
					// Policy Read
					// Policy Write
					// Policy Feature Write (Feature Namer)
					// USER_ROLES.ADA_API_POLICY_FEATURE_WRITE
					// USER_ROLES.ADA_API_POLICY_READ
					// USER_ROLES.ADA_API_POLICY_WRITE
					UserPermissions.ADA_API_POLICY_FEATURE_WRITE_NA,
					UserPermissions.ADA_API_POLICY_FEATURE_WRITE_EU,
					UserPermissions.ADA_API_POLICY_READ_NA,
					UserPermissions.ADA_API_POLICY_READ_EU,
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			}
		],
		display: true
	},
	{
		label: SceneLabels.POLICY_ELEMENTS,
		icon: Icons.Functions,
		scenes: [
			{
				label: "Histograms",
				path: "/histograms",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: "Sensors",
				path: "/sensors",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: "Synthetics",
				path: "/synthetics",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN,
					// USER_ROLES.CREATOR
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			}
		],
		display: true
	},
	{
		label: SceneLabels.STREAMS,
		icon: Icons.SyncAlt,
		path: "/streams",
		permissions: [
			// Roles required for scene access
			// USER_ROLES.ADMIN
			UserPermissions.ADA_API_STREAM_READ_WRITE_NA,
			UserPermissions.ADA_API_STREAM_READ_WRITE_EU
		],
		display: true
	},
	{
		label: SceneLabels.ADMINISTRATION,
		icon: Icons.People,
		scenes: [
			{
				label: "Users",
				path: "/users",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN
					UserPermissions.ADA_API_USER_READ_NA,
					UserPermissions.ADA_API_USER_READ_EU,
					UserPermissions.ADA_API_USER_WRITE_NA,
					UserPermissions.ADA_API_USER_WRITE_EU
				],
				display: true
			},
			{
				label: "Groups",
				path: "/groups",
				permissions: [
					// Roles required for scene access
					// USER_ROLES.ADMIN
					UserPermissions.ADA_API_USER_READ_NA,
					UserPermissions.ADA_API_USER_READ_EU,
					UserPermissions.ADA_API_USER_WRITE_NA,
					UserPermissions.ADA_API_USER_WRITE_EU
				],
				display: true
			}
		],
		display: true
	},
	{
		label: SceneLabels.DOCUMENTATION,
		icon: Icons.MenuBook,
		scenes: [
			{
				label: "API Documentation",
				path: "/api-docs",
				display: true
			},
			{
				label: "Support",
				path: "/support",
				display: true
			},
			{
				label: "Traceability Matrix",
				path: "/matrix",
				display: true
			}
		],
		display: true
	},
	{
		label: "Operations",
		icon: Icons.BuildTwoTone,
		path: IS_PRODUCTION_ENVIRONMENT
			? "https://ops.alldataapp.com"
			: "https://ops.dev.alldataapp.com", // no stage batch-ui
		permissions: [UserPermissions.ADA_API_WEB_ACCESS_EU, UserPermissions.ADA_API_WEB_ACCESS_NA],
		isExternal: true,
		display: IS_PRODUCTION_ENVIRONMENT || IS_DEV_ENVIRONMENT
	},
	{
		label: SceneLabels.POLICY_GOVERNANCE,
		icon: Icons.Gavel,
		scenes: [
			{
				label: "Request",
				path: "/governance/requests",
				permissions: [
					UserPermissions.ADA_API_POLICY_READ_NA,
					UserPermissions.ADA_API_POLICY_READ_EU,
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			},
			{
				label: "Approve",
				path: "/governance/approve",
				permissions: [
					UserPermissions.ADA_API_POLICY_READ_NA,
					UserPermissions.ADA_API_POLICY_READ_EU,
					UserPermissions.ADA_API_POLICY_WRITE_NA,
					UserPermissions.ADA_API_POLICY_WRITE_EU
				],
				display: true
			}
		],
		display: true
	}
];
